import React from "react";
import { Image, StyleSheet, View } from "react-native";

import { useTheme } from "react-native-paper";
import AppleSignIn from "../../apple-sign-in/AppleSignIn";
import { SignInErrorI, SignInResponseI } from "../../apple-sign-in";

type Props = {
    onSuccess: (response: SignInResponseI) => void;
    onError: (error: SignInErrorI) => void;
    loading: boolean;
}
const AppleSignInButton: React.FC<Props> = ({ onSuccess, onError, loading }) => {
    const theme = useTheme();

    return <View style={styles.buttonContainer}>
        <AppleSignIn
            color="white"
            borderRadius={50}
            width={200}
            height={40}
            type="continue"
            success={onSuccess}
            error={onError}
        />
        {loading && <Image aria-disabled={loading} source={require("../../assets/img/icons/loading.gif")} style={styles.loadingIcon} />}
    </View>
};

const styles = StyleSheet.create({
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 10
    },
    googleLogo: {
        objectFit: "contain",
        width: 18,
        height: 18,
        marginEnd: 30
    },
    loadingIcon: {
        width: 35,
        height: 35
    }
});

export default AppleSignInButton;
