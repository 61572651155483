import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ImageBackground, SafeAreaView, ScrollView, StatusBar, View } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import B from '../../../components/Bold';
import { NavigationWithProps } from '../../../core/Types';
import { PagesNavParamList } from '../Pages';
import { RootNavParamList } from '../../../../App';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useTherapists } from '../../../core/api/therapists';
import { TherapistModel } from '../../../core/models/TherapistModel';
import TherapistAppointmentCard from '../../../components/TherapistAppointmentCard';
import { AppStoreContext } from '../../../core/provider/AppStoreProvider';
import { PaginatedResponse } from '../../../core/models/ApiResponse';
import { isCloseToBottom } from '../../../utils/Native';
import { Background, Scenes } from '../../../assets/images';


const motivationalQuotes = [
    '"La mente es como un paracaídas: sólo funciona si se abre." (James Dewar) - Invita a ser receptivos a nuevas ideas y perspectivas.',
    '"No es lo que te pasa lo que importa, sino cómo reaccionas ante ello." (Epicteto) - Enfatiza la importancia de nuestra actitud frente a los desafíos.',
    '"La felicidad no es algo hecho. Viene de tus propias acciones." (Dalai Lama) - Subraya que nuestra felicidad está en nuestras manos.',
    '"El miedo a fracasar es mucho peor que el fracaso en sí." (Pablo Picasso) - Anima a salir de la zona de confort y tomar riesgos.',
    '"Lo que pensamos crea nuestra realidad." (Buddha) - La importancia de los pensamientos positivos en la creación de nuestra experiencia.'
];

const Therapist: React.FC<NavigationWithProps<{}, PagesNavParamList, "Therapists", "AppNavigator">> = ({ navigation, route }) => {

    const theme = useTheme();
    const therapists = useTherapists();

    const appContext = useContext(AppStoreContext);

    const [therapistList, setTherapistList] = useState<PaginatedResponse<TherapistModel> | undefined>(undefined);
    const [loadingNextPage, setLoadingNextPage] = useState(false);
    const [searchText, setSearchText] = useState('');

    const randomQuote = useMemo(() => {
        const index = Math.floor(Math.random() * motivationalQuotes.length);
        return motivationalQuotes[index];
    }, []);

    const openTherapistProfile = (therapist: TherapistModel) => {
        navigation
            .getParent<NativeStackNavigationProp<RootNavParamList>>("AppNavigator")
            ?.navigate("Therapist", therapist);
    }

    const fetchTherapists = async (searchText?: string) => {
        setTherapistList(undefined);

        searchText?.trim();
        const data = await therapists.listTherapists(searchText == "" ? undefined : searchText);
        setTherapistList(data);
    };

    const loadNextPage = async () => {
        if (therapistList?.hasNextPage !== true) return;

        setLoadingNextPage(true);
        const data = await therapists.listTherapists(searchText == "" ? undefined : searchText, therapistList.currentPage + 1);
        setLoadingNextPage(false);

        data.items = therapistList.items.concat(data.items);
        setTherapistList(data);
    };

    useEffect(() => {
        fetchTherapists();
        therapists.fetchMyTherapist();
    }, []);

    return (
        <SafeAreaView style={{
            flex: 1,
            backgroundColor: theme.colors.surfaceVariant,
            position: "relative",
        }}>
            <StatusBar
                translucent
                barStyle={theme.dark ? "light-content" : "dark-content"}
                backgroundColor="transparent"
            />

            <View>
                <ImageBackground
                    style={{
                        width: "100%",
                        height: 230,
                        gap: 15,
                    }}
                    imageStyle={{ resizeMode: 'cover' }}
                    source={Scenes.meditatingTent}>
                </ImageBackground>

                <View style={{
                    alignItems: "center",
                    paddingTop: 12,
                    borderTopColor: theme.colors.secondaryContainer,
                    borderTopWidth: 0.5,
                }}>
                    <Text style={{ fontSize: 25, color: theme.colors.onSurfaceVariant }}>
                        <B>Psicólogos</B>
                    </Text>
                </View>
            </View>

            <ScrollView style={{
                position: 'absolute',
                height: "100%",
                width: "100%",
            }}
                onMomentumScrollEnd={({ nativeEvent }) => {
                    if (isCloseToBottom(nativeEvent)) {
                        loadNextPage();
                    }
                }}>
                <View style={{ height: 300 }} />
                <View style={{
                    borderTopEndRadius: 30,
                    borderTopStartRadius: 30,
                    backgroundColor: theme.colors.surface
                }}>
                    <View style={{
                        paddingHorizontal: 20,
                        paddingTop: 20,
                        flex: 1
                    }}>
                        <Text style={{ fontSize: 20 }}><B>Mi psicólogo</B></Text>
                        <TherapistAppointmentCard
                            therapist={appContext.myTherapist?.value?.therapist}
                            appointment={appContext.myTherapist?.value?.next_appointment}
                            onShowProfile={(therapist) => openTherapistProfile(therapist)} />

                        <View style={{
                            minHeight: 10,
                            height: "calc(100vh - 770px)" as any
                        }} />

                        <Text style={{
                            fontSize: 19,
                            marginTop: 50,
                            marginBottom: 60,
                            margin: 10,
                            textAlign: 'center',
                            fontStyle: 'italic',
                            color: theme.colors.onSurface
                        }}>
                            {randomQuote}
                        </Text>

                    </View>
                    <ImageBackground
                        style={{
                            height: 100
                        }}
                        resizeMode='cover'
                        source={Background.small} />

                    {/* <Text style={{
                        fontSize: 20,
                        marginBottom: 20
                    }}><B>Explorar terapeutas</B></Text>
                    <Searchbar
                        style={{ marginBottom: 20 }}
                        placeholder="Buscar"
                        value={searchText}
                        onChangeText={setSearchText}
                        onSubmitEditing={() => fetchTherapists(searchText)}
                        onClearIconPress={() => fetchTherapists()} />

                    {
                        therapistList == undefined ?
                            <>
                                <CardListLoader />
                                <CardListLoader />
                            </> :
                            therapistList.items.map((therapist, index) => (
                                <TherapistCard
                                    key={index}
                                    therapist={therapist}
                                    onCardPress={openTherapistProfile} />
                            ))
                    }
                    {
                        loadingNextPage &&
                        <CardListLoader />
                    }
                    {
                        therapistList?.items.length == 0 &&
                        <Text style={{
                            textAlign: 'center',
                            fontSize: 20,
                            color: theme.colors.onSurfaceVariant,
                            marginTop: 20,
                            marginBottom: 100
                        }}>
                            No se encontraron terapeutas
                        </Text>
                    } */}
                </View>
            </ScrollView>
        </SafeAreaView >
    );
}

export default Therapist;
