import { Image, View } from "react-native";
import { TherapistModel } from "../core/models/TherapistModel";
import { Badge, Button, Text, useTheme } from "react-native-paper";
import B from "./Bold";
import { AppointmentStatus } from "../core/models/enums";
import { formatDateTime } from "../utils/Formatting";
import { useMemo } from "react";
import { Pet } from "../assets/images";
import { AppointmentModel } from "../core/models/AppointmentModel";
import { usePublicApi } from "../core/api/publicApi";
import { getAppointmentStatus, getProfileImageUrl } from "../utils/Procedures";

type Props = {
    therapist?: TherapistModel;
    appointment?: AppointmentModel;
    onShowProfile?: (therapist: TherapistModel) => void;
}

const TherapistAppointmentCard: React.FC<Props> = ({ therapist, appointment, onShowProfile }) => {

    const theme = useTheme();
    const publicApi = usePublicApi();

    const hasAppointment = useMemo(() => appointment != undefined &&
        [AppointmentStatus.Confirmed, AppointmentStatus.PendingConfirmation].includes(appointment.status), [appointment]);

    const confirmedColor = theme.colors.primary;
    const pendingColor = theme.colors.secondary;

    return (therapist == undefined ?
        <View style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Image
                style={{ width: 150, height: 150 }}
                source={Pet.reading} />
            <Text style={{
                fontSize: 20,
                textAlign: 'center',
                color: theme.colors.onSurfaceVariant,
                width: 180,
            }}>
                No hay psicólogo asignado
            </Text>
        </View>
        :
        <View style={{
            borderRadius: 30,
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            padding: 20,
            marginVertical: 20,
            backgroundColor: theme.colors.surfaceVariant, marginBottom: 40
        }}>
            <Image
                style={{ width: 100, height: 100, borderRadius: 50 }}
                source={getProfileImageUrl(therapist, publicApi)} />
            <View>
                {hasAppointment && <Badge style={{
                    alignSelf: 'flex-start',
                    backgroundColor: appointment?.status == AppointmentStatus.PendingConfirmation ? pendingColor : confirmedColor,
                    paddingHorizontal: 10,
                    marginBottom: 5
                }}>
                    {appointment ? getAppointmentStatus(appointment) : "na"}
                </Badge>}
                <Text style={{ fontSize: 20, color: theme.colors.onSurfaceVariant }}><B>{therapist.full_name}</B></Text>
                <Text style={{ fontSize: 14, color: theme.colors.onSurfaceVariant }}>
                    {hasAppointment ? formatDateTime(appointment!.time_slot) : 'No hay próximas sesiones'}
                </Text>
                {onShowProfile && <Button
                    contentStyle={{ flexDirection: 'row-reverse' }}
                    textColor={theme.colors.secondary}
                    icon='arrow-right'
                    onPress={() => onShowProfile(therapist)}>
                    Ver perfil
                </Button>}
            </View>
        </View>
    );
};

export default TherapistAppointmentCard;
