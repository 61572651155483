import { AppointmentModalityType, AppointmentSessionType, AppointmentStatus } from "./enums";

export interface IAppointment {
    id: number;
    user_id: number;
    therapist_id: number;
    status: AppointmentStatus;
    time_slot: Date;
    created_at: Date;
    session_type: AppointmentSessionType;
    modality_type: AppointmentModalityType;
    appointment_price: number;
    therapist?: { id: number, full_name: string, has_photo: boolean };
}

export class AppointmentModel implements IAppointment {
    id: number;
    user_id: number;
    therapist_id: number;
    status: AppointmentStatus;
    time_slot: Date;
    created_at: Date;
    session_type: AppointmentSessionType;
    modality_type: AppointmentModalityType;
    appointment_price: number;
    therapist?: { id: number, full_name: string, has_photo: boolean };

    get isCompleted() {
        const end = new Date(this.time_slot);
        end.setHours(end.getHours() + 1);
        return this.status === AppointmentStatus.Confirmed && end < new Date();
    }

    get hasStarted() {
        return this.status === AppointmentStatus.Confirmed && new Date() >= this.time_slot;
    }

    get isInProgress() {
        return this.status === AppointmentStatus.Confirmed && this.hasStarted && !this.isCompleted;
    }

    constructor(appointment: IAppointment) {
        this.id = appointment.id;
        this.user_id = appointment.user_id;
        this.therapist_id = appointment.therapist_id;
        this.status = appointment.status;
        this.time_slot = appointment.time_slot;
        this.created_at = appointment.created_at;
        this.session_type = appointment.session_type;
        this.modality_type = appointment.modality_type;
        this.appointment_price = appointment.appointment_price;
        this.therapist = appointment.therapist;
    }
}
