import React, { useContext, useEffect, useMemo, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import Login from './src/app/login/Login';
import Pages from './src/app/pages/Pages';
import { useTheme } from 'react-native-paper';
import { NavigationTheme } from 'react-native-paper/lib/typescript/types';
import { useOnboarding } from './src/core/api/onboarding';
import { TherapistModel } from './src/core/models/TherapistModel';
import Therapist from './src/app/Therapist/Therapist';
import { SessionContext } from './src/core/provider/SessionProvider';
import { useTherapists } from './src/core/api/therapists';
import Onboarding from './src/app/onboarding/Onboarding';

export type RootNavParamList = {
    "Login": undefined,
    "Onboarding": undefined,
    "Pages": undefined,
    "Therapist": TherapistModel
};

const Stack = createNativeStackNavigator<RootNavParamList>();

const App: React.FC = () => {

    const theme = useTheme();

    const session = useContext(SessionContext);

    const [ready, setReady] = useState(false);
    const [hasOnboarding, setHasOnboarding] = React.useState<boolean>();
    const [requestedTherapist, setRequestedTherapist] = useState<TherapistModel>();

    const hasRequestedTherapist = useMemo(() => {
        return requestedTherapist !== undefined;
    }, [requestedTherapist]);
    const hasSession = useMemo(() => {
        return session.session !== undefined;
    }, [session]);

    const onboarding = useOnboarding();
    const therapists = useTherapists();

    const checkOnboarding = async () => {
        if (!hasSession) {
            setHasOnboarding(false);
            return false;
        }

        const ok = await onboarding.isFilled()
            .catch((error) => {
                //TODO: Handle error
                console.error('Error:', error);
                return false;
            });
        console.log('ok:', ok);
        setHasOnboarding(ok);

        return ok;
    };

    const checkTherapistInPath = async () => {
        if (location.pathname !== "/therapist") {
            return;
        }
        const params = new URLSearchParams(location.search);
        const therapistId = Number(params.get('id'));
        if (!therapistId || isNaN(therapistId)) {
            return;
        }

        const therapist = await therapists.getTherapist(therapistId)
            .catch(() => undefined);
        if (!therapist) {
            return;
        }
        setRequestedTherapist(therapist);
    };

    const init = async () => {
        const ok = await checkOnboarding();
        if (ok) {
            await checkTherapistInPath();
            history.replaceState({}, '', '/');
        }
        setReady(true);
    };

    useEffect(() => { init(); }, []);

    return (<>
        {ready &&
            <NavigationContainer
                documentTitle={{
                    //TODO: We should put this in a constants file
                    formatter: (options, route) =>
                        "Kyra - Gestiona las citas con tu psicólogo",
                }}
                theme={theme as unknown as NavigationTheme}>
                <Stack.Navigator id='AppNavigator'
                    screenOptions={
                        { headerShown: false }
                    }
                    initialRouteName={hasSession ? (hasOnboarding ? (hasRequestedTherapist ? 'Therapist' : 'Pages') : 'Onboarding') : 'Login'}>
                    <Stack.Screen
                        name="Login"
                        component={Login}
                    />

                    <Stack.Screen
                        name="Onboarding"
                        component={Onboarding}
                    />

                    <Stack.Screen
                        name="Pages"
                        component={Pages}
                    />

                    <Stack.Screen
                        name="Therapist"
                        component={Therapist}
                        initialParams={requestedTherapist}
                    />
                </Stack.Navigator>
            </NavigationContainer>}
    </>);
}

export default App;
