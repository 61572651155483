import { useContext, useState } from "react";
import { IMessage } from "react-native-gifted-chat";
import { AppStoreContext } from "../provider/AppStoreProvider";

export const useIndexedDB = () => {

    const appContext = useContext(AppStoreContext);

    const _openChatDb = async (): Promise<IDBDatabase> => {
        if (appContext.chatDatabase?.value) {
            return appContext.chatDatabase!.value;
        }

        const request = window.indexedDB.open("chat", 1);
        return await new Promise((resolve: any, reject: any) => {
            request.onerror = (event) => {
                reject(event);
            };
            request.onsuccess = (event) => {
                appContext.chatDatabase?.set(request.result);
                resolve(request.result);
            };
            request.onupgradeneeded = (event) => {
                const store = request.result.createObjectStore("messages", { keyPath: "inc", autoIncrement: true });
                return new Promise((resolve: any, reject: any) => {
                    store.transaction.oncomplete = (event) => {
                        appContext.chatDatabase?.set(request.result);
                        resolve(request.result);
                    }
                });
            };
        });
    };

    const loadChatMessages = async (): Promise<IMessage[]> => {
        const database = await _openChatDb();
        const messages: IMessage[] = [];
        return await new Promise((resolve, reject) => {
            const request = database
                .transaction("messages", "readonly")
                .objectStore("messages")
                .openCursor(null, "prev");

            request.onerror = (event) => {
                reject(event);
            };

            request.onsuccess = (event) => {
                const cursor = request.result;
                if (cursor) {
                    messages.push(cursor.value);
                    cursor.continue();
                } else {
                    resolve(messages);
                }
            };
        });
    };

    const storeChatMessage = async (message: IMessage): Promise<void> => {
        const database = await _openChatDb();
        return await new Promise((resolve, reject) => {
            const request = database
                .transaction("messages", "readwrite")
                .objectStore("messages")
                .add(message);

            request.onerror = (event) => {
                reject(event);
            };

            request.onsuccess = (event) => {
                resolve();
            };
        });
    };

    const clearChatMessages = async (): Promise<void> => {
        const database = await _openChatDb();
        return await new Promise((resolve, reject) => {
            const request = database
                .transaction("messages", "readwrite")
                .objectStore("messages")
                .clear();

            request.onerror = (event) => {
                reject(event);
            };

            request.onsuccess = (event) => {
                resolve();
            };
        });
    };

    return {
        loadChatMessages,
        storeChatMessage,
        clearChatMessages
    };
};
