import { useContext, useEffect } from "react";
import { SignInErrorI, SignInResponseI } from ".";
import { AppleIDContext } from "./AppleSignInProvider";

interface Props {
    mode?: "center-align" | "left-align" | "logo-only";
    type?: "sign-in" | "continue" | "sign-up";
    color?: "black" | "white";
    border?: boolean;
    borderRadius?: number;
    width?: number;
    height?: number;
    logoSize?: "small" | "medium" | "large";
    labelPosition?: number;

    success: (detail: SignInResponseI) => void;
    error: (error: SignInErrorI) => void;
}

const AppleSignIn: React.FC<Props> = (props) => {
    const appleId = useContext(AppleIDContext);

    // Listen for authorization success.
    document.addEventListener("AppleIDSignInOnSuccess", (event) => {
        if ("detail" in event) {
            props.success(event.detail as SignInResponseI);
        }
    });

    // Listen for authorization failures.
    document.addEventListener("AppleIDSignInOnFailure", (event) => {
        if ("detail" in event) {
            props.error(event.detail as SignInErrorI);
        }
    });

    useEffect(() => {
        appleId?.auth.renderButton();
    }, []);

    return (<div
        style={{
            cursor: "pointer"
        }}
        id="appleid-signin"
        data-mode={props.mode}
        data-type={props.type}
        data-color={props.color}
        data-border={props.border}
        data-border-radius={props.borderRadius}
        data-width={props.width}
        data-height={props.height}
        data-logo-size={props.logoSize}
        data-label-position={props.labelPosition}
    >
    </div >);
};

export default AppleSignIn;
