import { useContext } from "react";
import { BASE_API, useHttp } from "../hooks/http";
import { IMyTherapist, MyTherapist, TherapistModel } from "../models/TherapistModel";
import { AppStoreContext } from "../provider/AppStoreProvider";
import { AppointmentModel, IAppointment } from "../models/AppointmentModel";
import { IPaginatedResponse, PaginatedResponse } from "../models/ApiResponse";


export const useTherapists = () => {
    const http = useHttp(BASE_API, true);

    const appContext = useContext(AppStoreContext);

    const getTherapist = async (therapistId: number): Promise<TherapistModel | undefined> => {
        return await http.get<TherapistModel>(`/userapi/therapists/${therapistId}`)
            .then((response) => response?.data);
    }

    const listTherapists = async (searchText?: string, pageNum: number = 1, pageSize: number = 10): Promise<PaginatedResponse<TherapistModel>> => {
        const response = await http.get<IPaginatedResponse<TherapistModel>>('/userapi/therapists/', {
            page_num: pageNum.toString(),
            page_size: pageSize.toString(),
            search: searchText
        })
            .then((response) => response?.data || { items: [], total: 0 })
            .catch((error) => {
                //TODO: Handle error
                return { items: [], total: 0 };
            });

        return new PaginatedResponse(response, pageNum, pageSize);
    }

    const fetchMyTherapist = async (): Promise<void> => {
        const response = await http.get<IMyTherapist>('/userapi/me/therapist').then((response) => {
            const data = response?.data;

            if (!data) {
                return undefined;
            }

            if (data.next_appointment) {
                data.next_appointment.time_slot = new Date(data.next_appointment.time_slot);
                data.next_appointment.created_at = new Date(data.next_appointment.created_at);
            }

            return data;
        }).catch((error) => {
            if (error.status === 404) {
                return undefined;
            }

            throw error;
        });

        appContext.myTherapist?.set(response ? new MyTherapist(response) : undefined);
    }

    const createAppointment = async (therapistId: number, timeSlot: Date): Promise<AppointmentModel> => {
        const response = await http.post<IAppointment>(`/userapi/therapists/${therapistId}/appointments/`, {
            time_slot: timeSlot.toISOString()
        }).then((response) => {
            const app = response?.data;
            if (!app) {
                throw new Error('No appointment data');
            }

            app.time_slot = new Date(app.time_slot);
            app.created_at = new Date(app.created_at);

            return app;
        });

        await fetchMyTherapist();

        return new AppointmentModel(response);
    }

    const cancelAppointment = async (therapistId: number, appointmentId: number): Promise<boolean> => {
        const response = await http.delete<boolean>(`/userapi/therapists/${therapistId}/appointments/${appointmentId}`)
            .then((response) => {
                return response?.ok || false;
            });

        await fetchMyTherapist();

        return response;
    }

    return {
        getTherapist,
        listTherapists,
        fetchMyTherapist,
        createAppointment,
        cancelAppointment
    };
};
