import { decodeBase64 } from "../../utils/Base64";

function _isExpired(payload: any): boolean {
    const now = Date.now() / 1000;
    return payload.exp < now;
}

export function getGoogleUser(credential: string) {
    const [header, payload, signature] = credential.split('.');
    const obj: any = JSON.parse(decodeBase64(payload));
    if (_isExpired(obj)) {
        throw new Error('Token expired');
    }

    if (!obj.sub || !obj.email) {
        throw new Error('Invalid token');
    }

    return {
        id: obj.sub,
        name: obj.name,
        email: obj.email,
        picture: obj.picture,
        familyName: obj.family_name,
        givenName: obj.given_name
    };
}
