import React, { useMemo, useState } from 'react';
import { Button, Chip, IconButton, Text, useTheme } from 'react-native-paper';
import { Calendar, DateData, LocaleConfig } from 'react-native-calendars';
import { NavigationWithProps } from '../../../core/Types';
import { TherapistNavParamList } from '../Therapist';
import { ImageBackground, LayoutChangeEvent, SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import B from '../../../components/Bold';
import { useTimeSlots } from '../../../core/api/timeSlots';
import { formatTime, formatDate } from '../../../utils/Formatting';
import { useAppointments } from '../../../core/api/appointments';
import { ChipListLoader } from '../../../components/Loaders';
import { useDialog } from '../../../core/hooks/dialog';
import LoadingIcon from '../../../components/LoadingIcon';
import { serializeAppointment } from '../../../utils/Serialize';
import { Background } from '../../../assets/images';
import { useTherapists } from '../../../core/api/therapists';

enum ScrollSource {
    SCROLL = 0,
    TIME_SLOTS = 1
};

LocaleConfig.locales['es'] = {
    monthNames: [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
        'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ],
    monthNamesShort: ['Ene.', 'Feb.', 'Mar.', 'Abr.', 'May.', 'Jun.', 'Jul.', 'Ago.', 'Sep.', 'Oct.', 'Nov.', 'Dic.'],
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    dayNamesShort: ['Dom.', 'Lun.', 'Mar.', 'Mié.', 'Jue.', 'Vie.', 'Sáb.'],
    today: "Hoy"
};
LocaleConfig.defaultLocale = 'es';

const TherapistCalendar: React.FC<NavigationWithProps<{}, TherapistNavParamList, "TherapistCalendar">> = ({ navigation, route }) => {

    const therapist = route.params;

    const theme = useTheme();
    const dialog = useDialog();

    const therapists = useTherapists();
    const timeSlots = useTimeSlots();

    const [calendarSelected, setCalendarSelected] = useState<string>('');
    const [selectedDay, setSelectedDay] = useState<Date | undefined>(undefined);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<number>(-1);
    const [timeSlotsList, setTimeSlotsList] = useState<Date[] | undefined>([]);
    const [loadingCreate, setLoadingCreate] = useState<boolean>(false);


    // -- EXTREME WORKAROUND FOR A BUG IN THE SCROLLVIEW --
    // The view "jumps" and glitches when the content is smaller than the scrollview.
    const [_scrollWidth, _setScrollWidth] = useState<number>(0);
    const [_timeSlotsWidth, _setTimeSlotsWidth] = useState<number>(0);
    const _canScroll = useMemo(() => _timeSlotsWidth > _scrollWidth, [_timeSlotsWidth, _scrollWidth]);
    const _onScrollLayout = (event: LayoutChangeEvent, source: ScrollSource) => {
        if (source === ScrollSource.SCROLL) {
            _setScrollWidth(event.nativeEvent.layout.width);
        } else {
            _setTimeSlotsWidth(event.nativeEvent.layout.width);
        }
    };
    // -- END OF WORKAROUND --


    const hasSelected = useMemo(() => !loadingCreate &&
        timeSlotsList != undefined &&
        selectedDay != undefined &&
        selectedTimeSlot >= 0, [loadingCreate, timeSlotsList, selectedDay, selectedTimeSlot]);

    const fetchTimeSlots = async (date: Date) => {
        const slots = await timeSlots.getFreeTimeSlots(therapist.id, date);
        setTimeSlotsList(slots);
    }

    const registerAppointment = async () => {
        if (!hasSelected) {
            return;
        }

        const time = timeSlotsList![selectedTimeSlot];
        const ok = await dialog
            .openIconDialog("calendar-question",
                "Confirmar cita",
                `Estás a punto se solicitar una cita con ${therapist.full_name} ` +
                `para el día ${formatDate(selectedDay!)} a las ${formatTime(time)}. ` +
                `¿Deseas continuar?`,
                "Solicitar",
                "Cancelar");

        if (!ok) {
            return;
        }

        setLoadingCreate(true);
        const appointment = await therapists.createAppointment(therapist.id, time);
        setLoadingCreate(false);

        if (appointment) {
            navigation.pop();
            navigation.replace("TherapistConfirmation", {
                therapist: therapist,
                appointment: serializeAppointment(appointment)
            });
        } else {
            dialog.openIconDialog("close-circle-outline",
                "Error creando cita",
                "No se ha podido solicitar la cita. Por favor, inténtalo de nuevo más tarde.");
        }
    };

    const updateSelectedDay = async (day: DateData) => {
        setTimeSlotsList(undefined);
        const date = new Date(day.year, day.month - 1, day.day);
        setCalendarSelected(day.dateString);
        setSelectedDay(date);
        setSelectedTimeSlot(-1);
        fetchTimeSlots(date);
    };

    return (
        <SafeAreaView style={{ flex: 1 }}>
            <ImageBackground
                style={{ flex: 1 }}
                source={Background.fullscreen}>
                <IconButton style={{
                    marginTop: 50,
                    marginLeft: 20,
                }}
                    onPress={() => navigation.goBack()}
                    icon="arrow-left" />

                <Text style={{
                    fontSize: 25,
                    color: theme.colors.onSurfaceVariant,
                    textAlign: "center",
                    marginTop: 20
                }}>
                    Calendario de <B>{therapist.full_name}</B>
                </Text>

                <View
                    style={{
                        marginTop: 20,
                        paddingHorizontal: 20,
                        paddingVertical: 20
                    }}
                >
                    <Calendar
                        style={{
                            backgroundColor: theme.colors.surface,
                        }}
                        theme={{
                            backgroundColor: theme.colors.onSecondary,
                            calendarBackground: theme.colors.surface,
                            dayTextColor: theme.colors.onSurface,
                            selectedDayBackgroundColor: theme.colors.secondaryContainer,
                            selectedDayTextColor: theme.colors.onSecondaryContainer,
                            todayTextColor: theme.colors.primary,
                            arrowColor: theme.colors.primary,
                        }}
                        onDayPress={updateSelectedDay}
                        firstDay={1}
                        minDate={new Date().toISOString()}
                        markedDates={selectedDay && {
                            [calendarSelected]: { selected: true, disableTouchEvent: true, selectedColor: theme.colors.secondaryContainer }
                        }} />

                    {timeSlotsList == undefined ? <ChipListLoader /> :
                        <ScrollView style={{
                        }}
                            onLayout={(ev) => _onScrollLayout(ev, ScrollSource.SCROLL)}
                            scrollEnabled={_canScroll}
                            horizontal={true}>
                            <View style={{ flexDirection: 'row' }}
                                onLayout={(ev) => _onScrollLayout(ev, ScrollSource.TIME_SLOTS)}>
                                {
                                    timeSlotsList.map((slot, index) => (
                                        <Chip
                                            key={index}
                                            selected={selectedTimeSlot == index}
                                            mode='outlined'
                                            style={{
                                                marginRight: 15,
                                                backgroundColor: selectedTimeSlot == index ? theme.colors.secondaryContainer : theme.colors.surface,
                                                marginVertical: 15,
                                            }}
                                            rippleColor={theme.colors.secondaryContainer}
                                            selectedColor={theme.colors.onSecondaryContainer}
                                            onPress={() => setSelectedTimeSlot(index)}>
                                            {formatTime(slot)}
                                        </Chip>
                                    ))
                                }
                            </View>
                        </ScrollView>}

                    {
                        selectedDay === undefined ?
                            <Text style={{
                                fontSize: 15,
                                marginVertical: 20,
                                color: theme.colors.onSurface
                            }}>
                                Selecciona un día para ver los horarios disponibles
                            </Text> :
                            timeSlotsList?.length === 0 &&
                            <Text style={{
                                fontSize: 15,
                                marginVertical: 20,
                                color: theme.colors.onSurface
                            }}>
                                No hay horarios disponibles para este día
                            </Text>
                    }

                    <View style={{
                        marginTop: 30,
                        flexDirection: 'row',
                    }}>
                        <Button
                            onPress={registerAppointment}
                            disabled={!hasSelected}
                            style={{
                                flexGrow: 1,
                                backgroundColor: hasSelected ? theme.colors.secondary : theme.colors.surfaceDisabled,
                                borderRadius: 30,
                            }}
                            mode='contained'
                            labelStyle={{
                                fontSize: 18,
                                paddingVertical: 10,
                                color: hasSelected ? theme.colors.onSecondary : theme.colors.onSurfaceDisabled,
                            }}
                            icon='arrow-right'
                            contentStyle={{
                                flexDirection: 'row-reverse',
                                justifyContent: "center",
                                alignContent: "flex-end"
                            }}>
                            Solicitar cita
                        </Button>
                        <LoadingIcon active={loadingCreate} />
                    </View>
                </View>
            </ImageBackground>
        </SafeAreaView >
    );
}

const style = StyleSheet.create({
    card: {
        paddingHorizontal: 20,
        marginHorizontal: 20,
        paddingVertical: 20,
        borderRadius: 30,
        marginTop: 20
    }
});

export default TherapistCalendar;
