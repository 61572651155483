import { AppointmentStatus } from "../core/models/enums";

export function appointmentStatusToString(status: AppointmentStatus) {
    switch (status) {
        case AppointmentStatus.PendingConfirmation:
            return "Pendiente de confirmar";
        case AppointmentStatus.PendingPayment:
            return "Pendiente de pago";
        case AppointmentStatus.Confirmed:
            return "Confirmada";
        case AppointmentStatus.CancelledByTherapist:
            return "Cancelada por psicólogo";
        case AppointmentStatus.CancelledByUser:
            return "Cancelada por usuario";
        default:
            return "Desconocido";
    }
}
