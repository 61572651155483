import { ApiResponse } from '../models/ApiResponse';
import { useAuth } from '../api/auth';
import { useContext } from 'react';
import { Session, SessionContext } from '../provider/SessionProvider';

type HttpParams = { [key: string]: string | undefined };

// export const BASE_API = "https://192.168.1.94:8080/api/v1";
export const BASE_API = "https://psicopro-api-ftdnvi23yq-no.a.run.app/api/v1";

export const useHttp = (baseUrl: string, authenticated: boolean) => {
    const auth = useAuth();

    const session = useContext(SessionContext);

    const __buildHeader = (sess?: Session, isJSON = true) => {
        const headers: HeadersInit = {};

        if (isJSON) {
            headers['Content-Type'] = 'application/json';
        }

        if (authenticated) {
            headers['Authorization'] = `Bearer ${sess ? sess?.accessToken : session?.session?.accessToken}`;
        }

        return headers;
    };

    const _withParams = (path: string, { params, baseUrl }: { params?: HttpParams, baseUrl?: string }): string => {
        const url = new URL(baseUrl + path);
        if (params) {
            Object.keys(params)
                .filter(key => params[key] !== undefined)
                .forEach(key => url.searchParams.append(key, params[key]!));
        }

        return url.toString();
    };

    const _fetcher = async <T>(url: string, init: RequestInit): Promise<ApiResponse<T> | undefined> => {
        const responseBody = (response: Response) => {
            if (!response.ok) {
                throw response;
            }

            return response.json()
        };

        return fetch(url, init)
            .then(responseBody)
            .catch((error) => {

                if (authenticated && error.status === 401) {
                    // Woops... you should not be here
                    auth.logout();
                }

                throw error;
            });
    };

    const get = async <T>(path: string, params?: HttpParams): Promise<ApiResponse<T> | undefined> => {
        return _fetcher(_withParams(path, { params, baseUrl }), {
            method: "GET",
            headers: __buildHeader(),
        });
    };

    const post = async <T>(path: string, data?: FormData | any): Promise<ApiResponse<T> | undefined> => {
        const formData = data instanceof FormData;
        return _fetcher(_withParams(path, { baseUrl }), {
            method: "POST",
            headers: __buildHeader(undefined, !formData),
            body: formData ? data : JSON.stringify(data)
        });
    };

    const put = async <T>(path: string, data?: FormData | any): Promise<ApiResponse<T> | undefined> => {
        const formData = data instanceof FormData;
        return _fetcher(_withParams(path, { baseUrl }), {
            method: "PUT",
            headers: __buildHeader(undefined, !formData),
            body: formData ? data : JSON.stringify(data)
        });
    };

    //delete is not allowed as a valid variable name
    const dellete = async <T>(path: string, params?: HttpParams): Promise<ApiResponse<T> | undefined> => {
        return _fetcher(_withParams(path, { params, baseUrl }), {
            method: "DELETE",
            headers: __buildHeader(),
        });
    };

    return {
        get baseUrl() { return baseUrl; },
        get,
        post,
        put,
        delete: dellete
    };
};
