import React from 'react';
import TermsAndConditionsStep from './steps/TermsAndConditionsStep';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { RootNavParamList } from '../../../App';
import { NavigationWithProps } from '../../core/Types';
import AuthorizationStep from './steps/AuthorizationStep';
import { AppleAuthenticateRequest } from '../../core/api/auth';

export type LoginNavParamList = {
    "Authorization": undefined,
    "TermsAndConditions": {
        provider: "google" | "apple",
        userName?: string,
        credential: string | AppleAuthenticateRequest
    }
};

const Stack = createNativeStackNavigator<LoginNavParamList>();
const Login: React.FC<NavigationWithProps<{}, RootNavParamList, "Login">> = ({ navigation }) => {

    return (
        <Stack.Navigator id='LoginNavigator' screenOptions={{
            headerShown: false
        }}>
            <Stack.Screen name="Authorization" component={AuthorizationStep} />
            <Stack.Screen name="TermsAndConditions" component={TermsAndConditionsStep} />
        </Stack.Navigator>
    );
}

export default Login;
