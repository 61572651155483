import { Animated, ImageSourcePropType, ScrollView, StyleProp, StyleSheet, ViewStyle } from "react-native";
import { Checkbox, useTheme } from "react-native-paper";
import { useEffect, useRef, useState } from "react";

type Props = {
    items: string[];
    checked?: number;
    onChecked: (index: number[]) => void;
    style?: StyleProp<ViewStyle>;
};

const CheckCardGroup: React.FC<Props> = ({ items, checked, onChecked, style }) => {

    const [checkStatus, setCheckStatus] = useState(items.map(() => false));
    const borderSnimValuesArr = useRef(items.map(() => new Animated.Value(1))).current;
    const theme = useTheme();

    const onPointerUp = (ix: number) => {
        const animVal = borderSnimValuesArr[ix];
        const isChecked = !checkStatus[ix];
        const newStatus = checkStatus.map((it, i) => i == ix ? isChecked : it);
        const checkedIx = newStatus.map((it, i) => it ? i : -1).filter((it) => it != -1);

        Animated.spring(animVal, {
            toValue: isChecked ? 5 : 1,
            useNativeDriver: false,
            speed: 40
        }).start();
        setCheckStatus(newStatus);
        onChecked(checkedIx);
    };

    useEffect(() => {
        if (checked) {
            onPointerUp(checked);
        }
    }, [checked]);

    return (
        <ScrollView style={[styles.rootView, style]}>
            {items.map((item, ix) => (
                <Animated.View key={ix}
                    onPointerUp={() => onPointerUp(ix)}
                    style={[
                        styles.cardItem,
                        {
                            borderColor: theme.colors.primary,
                            borderLeftWidth: borderSnimValuesArr[ix],
                        },
                        checkStatus[ix] ? {
                            backgroundColor: theme.colors.surfaceVariant,
                            // opacity: animValuesArr[ix]
                        } : {},
                    ]}>
                    <Checkbox.Item
                        label={item}
                        labelStyle={{ color: theme.colors.onSurfaceVariant }}
                        status={checkStatus[ix] ? "checked" : "unchecked"} />
                </Animated.View>
            ))}
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    rootView: {
        paddingHorizontal: 13
    },
    cardItem: {
        width: "100%",
        borderRadius: 10,
        borderWidth: 1,
        borderStyle: "solid",
        marginTop: 10,
    }
});

export default CheckCardGroup;
function ref(arg0: any) {
    throw new Error("Function not implemented.");
}

