import { useCallback, useEffect, useState } from "react";
import { NavigationWithProps } from "../../../core/Types";
import { PagesNavParamList } from "../Pages";
import { Bubble, Composer, GiftedChat, IMessage, InputToolbar, InputToolbarProps, Send } from "react-native-gifted-chat";
import { Pet } from "../../../assets/images";
import { IconButton, MD3Theme, Text, useTheme } from "react-native-paper";
import { View } from "react-native";
import { uuidv4 } from "../../../utils/uuid";
import { useIndexedDB } from "../../../core/hooks/indexeddb";
import { ChatMessageUserType, useChatbot } from "../../../core/api/chatbot";
import { useDialog } from "../../../core/hooks/dialog";

function bubbleRenderer(theme: MD3Theme) {
    return (props: Bubble<IMessage>['props']) => (
        <Bubble
            {...props}
            wrapperStyle={{
                right: {
                    backgroundColor: theme.colors.primary
                },
                left: {
                    backgroundColor: theme.colors.surface
                }
            }}
        />
    );
}

function inputToolbarRenderer(theme: MD3Theme, disableComposer: boolean = false) {
    return (props: InputToolbarProps<IMessage>) => (
        <InputToolbar
            {...props}
            containerStyle={{
                margin: 5,
                backgroundColor: theme.colors.surface,
                borderTopColor: theme.colors.surfaceVariant,
                borderRadius: 20,
                borderTopWidth: 1
            }}
            renderComposer={(props) => <Composer {...props} disableComposer={disableComposer} placeholder="Escribe algo..." textInputProps={{
                maxLength: 2000
            }} />}
            renderSend={(props) => <Send {...props} label="Enviar" textStyle={{
                color: theme.colors.primary
            }} />}
        />
    );
}

const Chat: React.FC<NavigationWithProps<{}, PagesNavParamList, "Chat", "AppNavigator">> = ({ navigation, route }) => {
    const theme = useTheme();

    const indexedDb = useIndexedDB();
    const chatbot = useChatbot();
    const dialog = useDialog();

    const [messages, setMessages] = useState<IMessage[]>([]);
    const [kyraLoading, setKyraLoading] = useState(false);

    const loadKyraResponse = async (message: string) => {
        const m = messages.map(message => ({
            type: message.user._id === 1 ? ChatMessageUserType.KYRA : ChatMessageUserType.USER,
            text: message.text
        })).reverse();

        m.push({
            type: ChatMessageUserType.USER,
            text: message
        });

        const response = await chatbot.autocomplete(m)
            .catch((error) => {
                console.error(error);
                if (error.status === 429) {
                    dialog.openDialog('Kyra no disponible',
                        'Kyra no se encuentrra disponible en este momento, por favor intenta de nuevo en 5 minutos.');
                } else {
                    dialog.openDialog('Error',
                        'Hubo un error al intentar comunicarse con Kyra, por favor intenta más tarde.');
                }
                setTimeout(() => {
                    setKyraLoading(false);
                }, 300000); // 5 minutes
                return null;
            })

        if (!response) {
            return;
        }

        setMessages(previousMessages => {
            previousMessages[0].text = response;
            indexedDb.storeChatMessage(previousMessages[0]);
            return previousMessages;
        });
        setKyraLoading(false);
    };

    const onSend = async (nMessages: IMessage[] = []) => {
        if (messages.length > 100) {
            dialog.openDialog('Máximo de mensajes',
                'Se ha alcanzado el número máximo de mensajes, por favor borra el historial de mensajes para continuar.');
            return;
        }

        setKyraLoading(true);
        loadKyraResponse(nMessages[0].text);

        await indexedDb.storeChatMessage(nMessages[0]);
        setMessages(previousMessages => {
            previousMessages.unshift(nMessages[0]);
            previousMessages.unshift({
                _id: uuidv4(),
                text: '...',
                createdAt: new Date(),
                user: {
                    _id: ChatMessageUserType.KYRA,
                    name: 'Kyra',
                    avatar: Pet.face
                },
            });

            return previousMessages;
        });
    };

    const clearChat = async () => {
        const message = {
            _id: uuidv4(),
            text: 'Hola, soy Kyra. Tu asistente de terapia virtual. ¿En qué puedo ayudarte hoy?',
            createdAt: new Date(),
            user: {
                _id: ChatMessageUserType.KYRA,
                name: 'Kyra',
                avatar: Pet.face
            },
        };
        await indexedDb.clearChatMessages();
        await indexedDb.storeChatMessage(message);
        setMessages([message]);
    };

    const restoreChat = async () => {
        const messages = await indexedDb.loadChatMessages();
        if (messages.length === 0) {
            await clearChat();
        } else {
            setMessages(messages);
        }
    }

    const goBack = () => {
        navigation.goBack();
    };

    useEffect(() => {
        restoreChat();
    }, []);

    return (<>
        <View style={{
            backgroundColor: theme.colors.background,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        }}>

            <IconButton style={{
                marginLeft: 10,
            }}
                onPress={goBack}
                icon="arrow-left" />
            <Text style={{
                fontSize: 20,
                marginLeft: 10,
                color: theme.colors.onBackground
            }}>
                Kyra
            </Text>

            <IconButton style={{
                marginLeft: "auto",
            }}
                onPress={clearChat}
                icon="restart" />
        </View>
        <View style={{ flex: 1, backgroundColor: theme.colors.surfaceVariant }}>
            <GiftedChat
                renderBubble={bubbleRenderer(theme)}
                renderInputToolbar={inputToolbarRenderer(theme, kyraLoading)}
                shouldUpdateMessage={(message) => message.currentMessage.user._id == 1 && message.nextMessage?._id === undefined}
                messages={messages}
                onSend={messages => onSend(messages)}
                user={{
                    _id: 2
                }}
            />
        </View>
    </>);
};

export default Chat;
