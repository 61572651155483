import { useContext } from "react";
import { SessionContext } from "../provider/SessionProvider";
import { ApiResponse, AuthResponse } from "../models/ApiResponse";
import { BASE_API } from "../hooks/http";
import { AuthorizationI } from "../../../apple-sign-in";

export interface AppleAuthenticateRequest extends AuthorizationI {
    full_name: string;
}

export const useAuth = () => {
    const session = useContext(SessionContext);

    if (!session.updateSession) {
        throw new Error('useSession must be used within a SessionProvider');
    }

    const googleLogin = async (credential: string, accept_terms?: boolean): Promise<void> => {
        const url = `${BASE_API}/userapi/auth/google`;
        return fetch(
            url,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ credential, accept_terms })
            })
            .then((response) => response.json())
            .then(async (data: ApiResponse<AuthResponse>) => {
                if (!data.ok) {
                    throw data;
                }

                session?.updateSession?.({ accessToken: data.data!.accessToken });
            });
    };

    const appleLogin = async (authorization: AppleAuthenticateRequest, accept_terms?: boolean): Promise<void> => {

        const url = `${BASE_API}/userapi/auth/apple`;
        return fetch(
            url,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ ...authorization, accept_terms })
            })
            .then((response) => response.json())
            .then(async (data: ApiResponse<AuthResponse>) => {
                if (!data.ok) {
                    throw data;
                }

                session?.updateSession?.({ accessToken: data.data!.accessToken });
            });
    };

    const logout = async (): Promise<void> => {
        session?.clearSession?.();
        location.replace('/');
    };

    return {
        googleLogin,
        appleLogin,
        logout
    };
};
