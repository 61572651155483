import { ReactNode, createContext, useEffect, useState } from "react";

export interface Session {
    accessToken: string;
}

type SessionContextProps = {
    session?: Session;
    updateSession?: (session?: Session) => void;
    clearSession?: () => void;
}

export const SessionContext = createContext<SessionContextProps>({});

export const SessionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [initializing, setInitializing] = useState<boolean>(true);
    const [session, setSession] = useState<Session | undefined>();

    const attemptSessionRestore = () => {
        const accessToken = sessionStorage.getItem("access_token");

        if (!accessToken) {
            return;
        }

        //TODO: Check token expiration
        setSession({ accessToken });
    };

    const setSessionAndStore = (session?: Session) => {
        if (!session) {
            sessionStorage.removeItem("access_token");
        } else {
            sessionStorage.setItem("access_token", session.accessToken);
        }

        setSession(session);
    };

    const clearSession = () => {
        sessionStorage.removeItem("access_token");
        setSession(undefined);
    };

    useEffect(() => {
        attemptSessionRestore();
        setInitializing(false);
    }, []);

    return (
        <SessionContext.Provider value={{ session, updateSession: setSessionAndStore, clearSession }}>
            {!initializing && children}
        </SessionContext.Provider>
    );
}
