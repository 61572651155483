import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import ConcernsStep from './steps/ConcernsStep';
import { RootNavParamList } from '../../../App';
import { NavigationWithProps } from '../../core/Types';

export type OnboaridngParams = {
    concerns: number[],
    dateOfBirth: Date
};

export type OnboardingNavParamList = {
    "TermsAndConditions": undefined
    "Concerns": undefined
    "AgeGroup": Pick<OnboaridngParams, "concerns">
};

const Stack = createNativeStackNavigator<OnboardingNavParamList>();
const Onboarding: React.FC<NavigationWithProps<{}, RootNavParamList, "Onboarding">> = ({ navigation }) => {

    return (
        <Stack.Navigator id='OnboardingNavigator' screenOptions={{
            headerShown: false
        }}>
            <Stack.Screen name="Concerns" component={ConcernsStep} />
        </Stack.Navigator>
    );
}

export default Onboarding;
