import React, { useEffect, useState } from 'react';
import { createMaterialBottomTabNavigator } from 'react-native-paper/react-navigation';
import Therapists from './therapists/Therapists';
import { Icon, useTheme } from 'react-native-paper';
import Appointments from './Appointments/Appointments';
import { useMe } from '../../core/api/me';
import Chat from './Chat/Chat';
import { useNavigation } from '@react-navigation/native';

export type PagesNavParamList = {
    "Therapists": undefined,
    "Chat": undefined,
    "Appointments": undefined
};

const Tab = createMaterialBottomTabNavigator<PagesNavParamList>();
const Pages: React.FC = () => {

    const navigation = useNavigation();
    const [showNavigationBar, setShowNavigationBar] = useState(true);
    const theme = useTheme();
    const me = useMe();

    useEffect(() => {
        me.fetchMyProfile();
    }, []);

    return (
        <Tab.Navigator id='PageNavigator'
            initialRouteName="Therapists"
            barStyle={{
                backgroundColor: theme.colors.surface,
                borderTopColor: theme.colors.surfaceVariant,
                borderTopWidth: 1,
                display: showNavigationBar ? 'flex' : 'none'
            }}
        >

            <Tab.Screen
                key="Pito"
                name="Therapists"
                component={Therapists}
                options={{
                    tabBarLabel: 'Inicio',
                    tabBarIcon: ({ focused, color }) => (
                        <Icon source={focused ? "account-heart" : "account-heart-outline"} color={color} size={26} />
                    )
                }}
                listeners={{
                    focus: () => setShowNavigationBar(true),
                }}
            />

            <Tab.Screen
                name="Chat"
                component={Chat}
                options={{
                    tabBarLabel: 'Chat',
                    tabBarIcon: ({ focused, color }) => (
                        <Icon source={focused ? "message" : "message-outline"} color={color} size={26} />
                    )
                }}
                listeners={{
                    focus: () => setShowNavigationBar(false),
                }}
            />

            <Tab.Screen
                name="Appointments"
                component={Appointments}
                options={{
                    tabBarLabel: 'Citas',
                    tabBarIcon: ({ focused, color }) => (
                        <Icon source={focused ? "calendar-blank" : "calendar-blank-outline"} color={color} size={26} />
                    )
                }}
                listeners={{
                    focus: () => setShowNavigationBar(true),
                }}
            />
        </Tab.Navigator>
    );
}

export default Pages;
