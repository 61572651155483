import React, { useEffect, useMemo, useState } from 'react';
import { Image, ImageBackground, Linking, StatusBar } from 'react-native';
import { View } from 'react-native';
import B from '../../../components/Bold';
import { Button, Text, useTheme } from 'react-native-paper';
import { NavigationWithProps } from '../../../core/Types';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Background, Pet } from '../../../assets/images';
import { LoginNavParamList } from '../Login';
import { useToast } from 'react-native-toast-notifications';
import { AppleAuthenticateRequest, useAuth } from '../../../core/api/auth';
import { getGoogleUser } from '../../../core/jwt/jwt';

const TermsAndConditionsStep: React.FC<NavigationWithProps<{}, LoginNavParamList, "TermsAndConditions">> = ({ navigation, route }) => {

    const theme = useTheme();
    const auth = useAuth();
    const toast = useToast();

    const { provider, userName, credential } = route.params;

    const [loading, setLoading] = useState(false);

    const shortName = useMemo(() => {
        if (userName) {
            return userName.split(" ")[0];
        }
        return undefined;
    }, [userName]);

    const signUpWithPolicyAccepted = () => {
        if (credential == undefined) {
            return;
        }

        let loginPromise: Promise<void>;
        if (provider === "google") {
            loginPromise = auth.googleLogin(credential as string, true);
        } else if (provider === "apple") {
            loginPromise = auth.appleLogin(
                credential as AppleAuthenticateRequest,
                true
            );
        } else {
            console.error("Invalid provider");
            return;
        }

        setLoading(true);
        loginPromise
            .then(async () => {
                location.reload();
            })
            .catch(onError)
            .finally(() => {
                setLoading(false);
            });
    }

    const onError = () => {
        console.log('Error:', 'Error');
        toast.show("Error al iniciar sesión", { duration: 1000 });
    }

    const openTermsAndConditions = () => {
        Linking.openURL("https://psicopro.co/terminos-y-condiciones/");
    }

    const openPrivacyPolicy = () => {
        Linking.openURL("https://psicopro.co/politica-de-privacidad/");
    }

    return (
        <SafeAreaView style={{ flex: 1 }}>
            <StatusBar
                barStyle={theme.dark ? "light-content" : "dark-content"}
                backgroundColor="transparent"
                translucent={true}
            />
            <ImageBackground
                style={{ flex: 1, paddingHorizontal: 20 }}
                source={Background.fullscreen}>
                <View style={{
                    alignItems: "center",
                    marginTop: 70
                }}>
                    <Image
                        source={Pet.meditating}
                        style={{
                            objectFit: "contain",
                            width: 250,
                            height: 250,
                        }}
                    />
                </View>
                <Text style={{
                    fontSize: 35,
                    textAlign: "center",
                    marginTop: 40
                }}>
                    {shortName ? <B>Hola {shortName}!</B> : <B>¡Bienvenido!</B>}
                </Text>
                <View style={{
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    marginTop: 50
                }}>
                    <Button mode="contained" onPress={signUpWithPolicyAccepted} loading={loading}>
                        Continuar
                    </Button>
                </View>
                <Text style={{
                    marginTop: "auto",
                    marginBottom: 130,
                    fontSize: 18,
                    textAlign: "center",

                }}>
                    Continuando confirmas que eres mayor de 16 años
                    y aceptas los <Text onPress={openTermsAndConditions}><B>Términos y Condiciones</B></Text>
                    y la <Text onPress={openPrivacyPolicy}><B>Política de privacidad</B></Text>.
                </Text>
            </ImageBackground>
        </SafeAreaView >
    );
}

export default TermsAndConditionsStep;
