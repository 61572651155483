import { useContext } from "react";
import { BASE_API, useHttp } from "../hooks/http";
import { AppStoreContext } from "../provider/AppStoreProvider";
import { Concern, Language } from "../models/enums";


export interface IUser {
    id: number;
    email?: string;
    full_name: string;
    data?: {
        language: Language
        concerns: Concern[]
    };
}

export class User implements IUser {
    public id: number;
    public email?: string;
    public full_name: string;
    public data?: IUser["data"];

    constructor(user: IUser) {
        this.id = user.id;
        this.email = user.email;
        this.full_name = user.full_name;
        this.data = user.data;
    }

    get shortName() {
        return this.full_name.split(" ")[0];
    }
}

export const useMe = () => {
    const http = useHttp(BASE_API, true);

    const appContext = useContext(AppStoreContext);

    const fetchMyProfile = async (): Promise<void> => {
        await http.get<IUser>('/userapi/me/')
            .then((response) => {
                if (response?.data) {
                    appContext.myProfile?.set(new User(response.data));
                }
            });
    }

    return {
        fetchMyProfile
    };
};
