import { ReactNode, createContext, useState } from "react";
import { View } from "react-native";
import { Button, Dialog, Text, useTheme } from "react-native-paper";


type SessionContextProps = {
    showDialog: (title: string, constent: string, accept?: string, decline?: string) => Promise<boolean>;
    showIconDialog: (icon: string, title: string, constent: string, accept?: string, decline?: string) => Promise<boolean>;
}

function wrapper(callback: (...args: any) => any) {
    return function () {
        return callback;
    }
}

export const DialogContext = createContext<SessionContextProps>({
    showDialog: async () => false,
    showIconDialog: async () => false,
});

export const DialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const theme = useTheme();

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [dialogContent, setDialogContent] = useState<string>('');

    const [dialogAccept, setDialogAccept] = useState<string | undefined>();
    const [dialogCancel, setDialogCancel] = useState<string | undefined>();

    const [dialogIcon, setDialogIcon] = useState<string | undefined>();

    const [acceptCallback, setAcceptCallback] = useState<undefined | (() => void)>();
    const [declineCallback, setDeclineCallback] = useState<undefined | (() => void)>();

    const returnPromise = () => {
        return new Promise<boolean>((resolve) => {
            setAcceptCallback(wrapper(() => {
                resolve(true);
                setAcceptCallback(undefined);
            }));

            setDeclineCallback(wrapper(() => {
                resolve(false);
                setDeclineCallback(undefined);
            }));
        });
    };

    const showDialogHandler = (title: string, content: string, accept: string = "Aceptar", decline?: string) => {
        setDialogIcon(undefined);
        setDialogTitle(title);
        setDialogContent(content);
        setDialogAccept(accept);
        setDialogCancel(decline);
        setShowDialog(true);
        return returnPromise();
    };

    const showIconDialogHandler = (icon: string, title: string, content: string, accept: string = "Aceptar", decline?: string) => {
        setDialogIcon(icon);
        setDialogTitle(title);
        setDialogContent(content);
        setDialogAccept(accept);
        setDialogCancel(decline);
        setShowDialog(true);
        return returnPromise();
    };

    const onAction = (isDismised: boolean) => {
        setShowDialog(false);
        if (isDismised) {
            declineCallback?.();
        } else {
            acceptCallback?.();
        }
    };

    return (
        <DialogContext.Provider value={{ showDialog: showDialogHandler, showIconDialog: showIconDialogHandler }}>
            {children}
            <Dialog visible={showDialog} onDismiss={() => onAction(true)}>
                {dialogIcon && <Dialog.Icon icon={dialogIcon} />}
                <Dialog.Title style={{
                    textAlign: "center"
                }}>{dialogTitle}</Dialog.Title>
                <Dialog.Content>
                    <Text variant="bodyMedium">{dialogContent}</Text>
                </Dialog.Content>
                <Dialog.Actions>
                    {dialogCancel && <Button
                        style={{ borderColor: theme.colors.error }}
                        labelStyle={{
                            fontSize: 20,
                            color: theme.colors.error
                        }}
                        onPress={() => onAction(true)}>{dialogCancel}</Button>
                    }
                    {dialogAccept && <Button
                        style={{ borderColor: theme.colors.primary }}
                        labelStyle={{
                            fontSize: 20,
                        }}
                        onPress={() => onAction(false)}>{dialogAccept}</Button>
                    }
                </Dialog.Actions>
            </Dialog>
        </DialogContext.Provider>
    );
}
