import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Animated, Image, ImageBackground, SafeAreaView } from 'react-native';
import { View } from 'react-native';
import { Button, Text, useTheme } from 'react-native-paper';
import { OnboardingNavParamList } from '../Onboarding';
import { NavigationWithProps } from '../../../core/Types';
import B from '../../../components/Bold';
import CheckCardGroup from '../../../components/CheckCard';
import { useOnboarding } from '../../../core/api/onboarding';
import { Language } from '../../../core/models/enums';
import { Background, Pet } from '../../../assets/images';
import { useToast } from 'react-native-toast-notifications';


const concernList = [
    "Salud Mental",
    "Estabilidad Financiera",
    "Seguridad Laboral",
    "Relaciones",
    "Crecimiento Personal",
    "Equilibrio Trabajo-Vida",
    "Familia",
    "Vida Social",
    "Comunidad",
    "Espiritualidad",
    "Hobbies",
    "Actividad Física"
];

const minChecks = 3;
let toValue = 0;

const ConcernsStep: React.FC<NavigationWithProps<{}, OnboardingNavParamList, "Concerns">> = ({ navigation, route }) => {

    const onboarding = useOnboarding();
    const theme = useTheme();
    const toast = useToast();

    const [checksNum, setChecksNum] = useState(0);
    const [concerns, setConcerns] = useState<number[]>([]);

    const buttonSlideAnim = useRef(new Animated.Value(0)).current;

    const checkConcern = (indexes: number[]) => {
        setConcerns(indexes);
        setChecksNum(indexes.length);
    };

    const onContinue = () => {
        onboarding.onboard({
            language: Language.ES, //TODO: Get language from the phone
            concerns: concerns.map((ix) => ix + 1)
        }).then((ok) => {
            console.log("Onboarding finished", ok);
            if (ok) {
                location.reload();
            }
        }).catch((error) => {
            toast.show("Error al finalizar el registro", { duration: 1000 });
        });
    };

    const remainChecks = useMemo(() => minChecks - checksNum, [checksNum]);

    useEffect(() => {
        toValue = checksNum > 0 ? 0 : 100;
        Animated.spring(buttonSlideAnim, {
            toValue,
            useNativeDriver: true,
            speed: 40
        }).start();
    }, [checksNum]);

    return (
        <SafeAreaView style={{ flex: 1 }}>
            <ImageBackground
                style={{ flex: 1 }}
                source={Background.fullscreen}>
                <View style={{
                    marginTop: 50,
                    flexDirection: "row",
                    height: "20%",
                }}>
                    <Image
                        source={Pet.thinking}
                        style={{
                            width: "50%",
                            height: "100%",
                        }}
                    />
                    <Text style={{
                        width: "45%",
                        fontSize: 20,
                    }}>
                        Hablemos de lo que te <B>preocupa</B>. Elige 3 elementos de la lista
                    </Text>
                </View>
                <CheckCardGroup
                    style={{
                        marginTop: 10,
                        marginBottom: 10,
                        height: "100%",
                    }}
                    items={concernList}
                    onChecked={checkConcern} />
                <Animated.View style={{
                    flexDirection: "row",
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    bottom: 0,
                    width: "100%",
                    height: "10%",
                    marginTop: 20,
                    backgroundColor: theme.colors.surfaceVariant,
                    display: "flex",
                    transform: [{ translateY: buttonSlideAnim }]
                }}>
                    <Text style={{
                        width: "70%",
                        fontSize: 20,
                        paddingHorizontal: 13,
                        color: theme.colors.onSurfaceVariant
                    }}>
                        {
                            remainChecks > 0 ?
                                (<>Selecciona al menos <B>{remainChecks}</B> elementos más</>) :
                                (<>¡Listo! finalizemos el registro</>)
                        }
                    </Text>
                    <Button
                        icon="arrow-right"
                        mode="contained"
                        style={{ marginRight: 13 }}
                        disabled={minChecks - checksNum > 0}
                        contentStyle={{ flexDirection: "row-reverse" }}
                        onPress={() => { onContinue() }}>
                        Siguiente
                    </Button>
                </Animated.View>
            </ImageBackground>
        </SafeAreaView >
    );
}

export default ConcernsStep;
