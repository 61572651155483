import { ClientConfigI, IAppleID } from ".";

import React, { ReactNode } from "react";

declare const AppleID: IAppleID;

export const AppleIDContext = React.createContext<IAppleID | null>(null);

export const AppleSignInProvider: React.FC<{ options: ClientConfigI, children: ReactNode }> = ({ options, children }) => {
    AppleID.auth.init(options);
    return <AppleIDContext.Provider value={AppleID}>{children}</AppleIDContext.Provider>;
};

export default AppleSignInProvider;
