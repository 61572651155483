export function base64ToBytes(base64: string): Uint8Array {
    const binString = atob(base64);
    return Uint8Array.from(binString as any, (m: any) => m.codePointAt(0));
}

export function bytesToBase64(bytes: Uint8Array): string {
    const binString = Array.from(bytes, (byte) =>
        String.fromCodePoint(byte),
    ).join("");
    return btoa(binString);
}

export function decodeBase64(base64: string): string {
    return new TextDecoder().decode(base64ToBytes(base64));
}

export function encodeBase64(str: string): string {
    return bytesToBase64(new TextEncoder().encode(str));
}
